import React, { useContext, useEffect, useState,useCallback } from "react";
import Chat from "../components/Chat";
import SideBar from "../components/Sidebar";
import Offline from "../components/Offline";
// import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { BASE_URL2 } from "../api/api";
import NewChatCall from "../components/ui-conponents/NewChatCall";
import { ChatState } from "../context/AllProviders";
import ContactDetailCard from "../components/Contactdetailcard/ContactDetailCard";
import NotesCard from "../components/Notescard/NotesCard";
import { ChatContext } from "../context/ChatContext";
import QuickReply from "../components/QuickReply/QuickReply";
import { useLocation } from "react-router-dom";
const { v4: uuidv4 } = require("uuid");

const Home = () => {
  const [showContactDetail, setShowContactDetail] = useState(false);
  const [showNotesCard, setshowNotesCard] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [showQuickReply, setshowQuickReply] = useState(false);
  const [activeTab, setActiveTab] = useState('Reply');
  const [showAllReminder, setShowAllReminder] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [todayRemCount, setTodayRemCount] = useState(0);
  const { data } = useContext(ChatContext);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { currentUser } = useContext(AuthContext);
  const { setCallData, callData, socket,setSelectedMobileNumber } = ChatState();
  const[keyboardOpen,setKeyBoardOpen]=useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryMobileNumber = queryParams.get("mob");

  

  useEffect(()=>{
    if(queryMobileNumber){
      setSelectedMobileNumber(queryMobileNumber);
    }else{
      setSelectedMobileNumber(null);
    }
  },[queryMobileNumber,setSelectedMobileNumber]);


  useEffect(() => {
    if (currentUser.parent_id) {
      setTimeout(() => {
        requestPermission();
      }, 2000);
    }
  }, [currentUser]);




  useEffect(() => {
    if (!currentUser.parent_id||!socket) return;
    const handleParallelChat = (data) => {
      if (currentUser.user_type === "agent") {
        setCallData({
          visible: true,
          name: data.name,
          mobile: data.mobile,
          message_type: data.message_type,
          content: data.message_content,
        });

        setTimeout(() => {
          setCallData(prevState => ({
            ...prevState,
            visible: false,
          }));
        }, 1000 * 60);
      }
    };
    const handleClosePopup = (data) => {
      if (data.accept === true) {
        setCallData(prevState => ({
          ...prevState,
          visible: false,
        }));
      }

    }
    socket.on("parallel chat", handleParallelChat);
    socket.on("chat accepted", handleClosePopup)
    return () => {
      socket.off("parallel chat", handleParallelChat);
      socket.off("chat accepted", handleClosePopup);

    };
  }, [currentUser, socket]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const fcmtoken = await getToken( {
        vapidKey:
          "BEhln3rQmB-b2irv__7ZkHknDX1kSJENc2cHGEfGmRs4ctE4j5h1n3WucKxRXBBGnVWwuoa2F4-aZ2gtK-rBgNQ",
      });

      const deviceid = localStorage.getItem("deviceid");
      let devid;
      if (!deviceid) {
        const newdeviceid = uuidv4();
        localStorage.setItem("deviceid", newdeviceid);
        devid = newdeviceid;
      } else {
        devid = deviceid;
      }
      const sendData = {
        user_id: currentUser.parent_id,
        token: currentUser.parent_token,
        fcmtoken,
        method: "create",
        deviceid: devid,
        user_type: currentUser.user_type === "admin" ? "admin" : "agent",
        agent_id: currentUser.user_id,
      };
      await axios.post(`${BASE_URL2}/fcm_token`, sendData);
    } else if (permission === "denied") {
      // alert("You denied for the notification");
      // const sendData = {
      //   user_id: lStorage.user.userId,
      //   method: "delete",
      // };
      // await api.post("api/fcm_token.php", sendData);
    }
  };

  const handleGlobalClick = useCallback(
  (e) => {
    // Check if the click is inside the reminder container or one of the reminder toggle divs
    const clickedInsideReminder = e.target.closest(".reminder-container");
    const clickedInsideReminderToggle = e.target.closest(".reminder-toggle") || e.target.closest(".reminder-toggle-all");
    const clickedInsideReminderModal=e.target.closest(".reminder-modal");
    const clickedInsideDatePicker = e.target.closest(".react-datepicker");

    // If click is outside of these areas, hide the reminders
    if (!clickedInsideReminder && !clickedInsideReminderToggle && !clickedInsideReminderModal && !clickedInsideDatePicker) {
      setShowReminder(false);
      setShowAllReminder(false);
    }
  },
  [showReminder, showAllReminder, setShowReminder, setShowAllReminder]
);


  useEffect(() => {
    document.addEventListener("click", handleGlobalClick);
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [handleGlobalClick]);
 
  
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100" style={{overflowY:"hidden"}}>
      <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-center align-items-start w-100 "  >
        {isOnline ? (
          <>
            <SideBar showAllReminder={showAllReminder}  setShowAllReminder={setShowAllReminder} todayRemCount={todayRemCount} setTodayRemCount={setTodayRemCount}/>
            <Chat keyboardOpen={keyboardOpen} setKeyBoardOpen={setKeyBoardOpen}  setNotesLoading={setNotesLoading} notes={notes} setNotes={setNotes} setshowQuickReply={setshowQuickReply} setShowContactDetail={setShowContactDetail} setshowNotesCard={setshowNotesCard} activeTab={activeTab} setActiveTab={setActiveTab} showReminder={showReminder} setShowReminder={setShowReminder} setTodayRemCount={setTodayRemCount} />
            {callData.visible === true && <NewChatCall />}
            {data?.selectedMobile && showContactDetail && <ContactDetailCard showContactDetail={showContactDetail} setShowContactDetail={setShowContactDetail} />}
            {data?.selectedMobile && showNotesCard&& <NotesCard notes={notes} setNotes={setNotes} notesLoading={notesLoading} setActiveTab={setActiveTab} setshowNotesCard={setshowNotesCard} showContactDetail={showContactDetail} />}
            {data?.selectedMobile && showQuickReply&& <QuickReply setActiveTab={setActiveTab} setshowQuickReply={setshowQuickReply} setshowNotesCard={setshowNotesCard} showContactDetail={showContactDetail} />}
            
          </>
        ) : (
          <Offline />
        )}
      </div>
    </div>
  );
};

export default Home;
