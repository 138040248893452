import { IoCloseSharp } from "react-icons/io5";
import style from './menulist.module.css';
import { ChatState } from "../../context/AllProviders";
import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import { sendMessage } from "../../api/api";
import { v4 as uuidv4 } from "uuid";
const MenuList = ({ button, list, setListOpen }) => {
    const { data,dispatch } = useContext(ChatContext);
    const { setText, setIsOldMsg,text } = ChatState();
    const [showFooter, setShowFooter] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const handleClose = () => {
        setListOpen(false);
    };

    const handleSelect = (text) => {
        console.log(text);
        setText(text);
        setShowFooter(true);

    }

    const handleSend = () => {
        const uid = uuidv4();
        const date = new Date();
        const msg = {
            agent_id: currentUser.user_type === "admin" ? "1" : currentUser.user_id,
            agent_name:
                currentUser.user_type === "admin" ? "admin" : currentUser.name,
            manager_id:
                currentUser.user_type === "admin" ? "1" : currentUser.manager,
            manager_name:
                currentUser.user_type === "admin"
                    ? "admin"
                    : currentUser.manager_name,
            team_id: currentUser.user_type === "admin" ? "1" : currentUser.team,
            team_name:
                currentUser.user_type === "admin" ? "admin" : currentUser.team_name,
            track_id: uid,
            mobile: data.selectedMobile,
            brand_number: currentUser.brand_number,
            message_type: "TEXT",
            req_from: "AGENT_REPLY",
            file_url: "",
            message_content: text,
            image_caption: "",
            resp_url: "",
            status: "pending",
            created: date,
        };

        let newdata = [...data.conversion, msg];

        dispatch({
            type: "CHANGE_USER",
            payload: {
                mobile: data.selectedMobile,
                conversation: newdata,
                name: data.selectedName,
            },
        });

        let body = {
            agent_id: currentUser.user_type === "admin" ? "1" : currentUser.user_id,
            agent_name:
                currentUser.user_type === "admin" ? "admin" : currentUser.name,
            manager_id:
                currentUser.user_type === "admin" ? "1" : currentUser.manager,
            manager_name:
                currentUser.user_type === "admin"
                    ? "admin"
                    : currentUser.manager_name,
            team_id: currentUser.user_type === "admin" ? "1" : currentUser.team,
            team_name:
                currentUser.user_type === "admin" ? "admin" : currentUser.team_name,
            track_id: uid,
            token: currentUser.parent_token,
            user_id: currentUser.parent_id,
            method: "reply",
            brand_number: currentUser.brand_number,
            mobile: data.selectedMobile,
            content: text,
        };
        setText("");
        sendMessage(body).then((res) => {
            if (res.data.success === true) {
                const updatedChat = newdata.map((chatdata) => {
                    if (chatdata.track_id === res.data.track_id) {
                        return { ...chatdata, status: "Submitted" };
                    }
                    return chatdata;
                });

                dispatch({
                    type: "CHANGE_USER",
                    payload: {
                        mobile: data.selectedMobile,
                        conversation: updatedChat,
                        name: data.convData.selectedName,
                    },
                });
                // setText("");
            } else {
                if (
                    res.data.message ===
                    "You need to send fresh reply coz chat 24 hour old"
                ) {
                    setIsOldMsg(true);
                }

            }
        });
        setListOpen(false);
    }



return (
    <>
        <div className={style.overlay}></div>
        <div className={style.menuCard}>
            <header className={style.header}>
                <h4 className={style.title}>{button}</h4>
                <IoCloseSharp
                    onClick={handleClose}
                    className={style.closeIcon}
                />
            </header>
            <main className={style.listContainer}>
                {list.map((item) => (
                    <label key={item.id} className={style.listItem}>
                        <input
                            type="radio"
                            name="menuOptions"
                            value={item.title}
                            id={item.id}
                            className={style.radioInput}
                            onChange={() => handleSelect(item.title)}
                        />
                        {item.title}
                    </label>
                ))}
            </main>

            {showFooter && <footer className="d-flex p-2 justify-content-end align-items-center w-100">
                <button
                    onClick={handleSend}
                    className="btn btn-primary btn-lg chat-send waves-effect waves-light"
                    data-bs-toggle="collapse"
                    data-bs-target=".chat-input-collapse1.show"
                    title="Send message"
                >
                    <i
                        className="bx bxs-send align-middle"
                        id="submit-btn"
                    />
                </button>
            </footer>}
        </div>
    </>
);
};

export default MenuList;
