import React from "react";
import style from "./deleteModal.module.css";

const DeleteModal = ({ show, onConfirm, onCancel }) => {
    if (!show) return null;

    return (
        <div className={`${style.overlay}`}>
            <div className={`${style.modal}`}>
                <h4 className={`${style.title}`}>Are you sure?</h4>
                <p>Do you really want to delete this item? This action cannot be undone.</p>
                <div className="d-flex justify-content-end mt-3">
                    <button className="btn btn-secondary mx-2" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="btn btn-danger" onClick={onConfirm}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
