
export const timeAgo=(dateString)=> {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  const units = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 }
  ];

  for (let unit of units) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
          return `${interval} ${unit.label}${interval > 1 ? 's' : ''} ago`;
      }
  }
  return "just now";
}

export const convertTimestamp = (timestamp) => {
  let date = timestamp.toDate();
  let mm = date.getMonth();
  let dd = date.getDate();
  let yyyy = date.getFullYear();
  date = mm + "/" + dd + "/" + yyyy;
  return date;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp); // Convert string to Date object
  const options = {
      year: 'numeric',
      month: 'short', // Use 'long' for full month name
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // For 12-hour format; set to false for 24-hour format
  };
  return date.toLocaleString('en-US', options);
};

// Function to set cookie
export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// Function to get cookie value
export const getCookie = (name) => {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  window.location.href = "/";
};


export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const parseReminder = (text) => {
  const regex = /^(.*?)(\d+):(.*)$/; // Matches name, number, and text
  const match = text.match(regex);

  if (match) {
      const name = match[1].trim(); // Name is before the number
      const number = match[2].trim(); // Number is the sequence of digits
      const reminderText = match[3].trim(); // Text is after the colon
      return { name, number, reminderText };
  }
  return { name: "", number: "", reminderText: text }; // Fallback
};