import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import style from './notesCard.module.css';
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { ChatState } from "../../context/AllProviders";
import { formatTimestamp } from "../../utils/Utils";
import { toast } from "react-toastify";
import DeleteModal from "../DeleteModal/DeleteModal";
import { BASE_URL2 } from "../../api/api";
import { CircularProgress } from '@mui/material';

const NotesCard = ({ notes, setNotes, setshowNotesCard, setActiveTab, notesLoading }) => {
    const [currentNote, setCurrentNote] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editText, setEditText] = useState("");


    const { currentUser } = useContext(AuthContext);

    const { selectedMobileNumber } = ChatState();


    const handleClose = () => {
        setshowNotesCard(false);
        setActiveTab("Reply");
    };

    const handleAdd = async () => {
        if (currentNote === "") return;
        setIsAdding(true);
        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "create",
            brand_number: currentUser.brand_number,
            mobile: selectedMobileNumber,
            text: currentNote,
            agent_id: currentUser.user_type === "admin" ? 0 : currentUser.user_id,
            agent_name: currentUser.user_type === "admin" ? "admin" : currentUser.name
        };
        const newNote = {
            agent_name: currentUser.user_type === "admin" ? "admin" : currentUser.name,
            date: new Date().toISOString(),
            text: currentNote,
            agent_id: currentUser.user_type === "admin" ? 0 : currentUser.user_id,
            _id: `temp-${Date.now()}`

        };
        setNotes((prevNotes) => [...prevNotes, newNote]);
        setCurrentNote(""); // Clear input field after successful addition

        try {
            const response = await axios.post(`${BASE_URL2}/whatsapp_notes`, body);
            if (response.data.success) {
                setNotes((prevNotes) =>
                    prevNotes.map((note) => note._id === newNote._id
                        ? { ...note, _id: response.data.id }
                        : note
                    )
                )
                toast.success("Note added successfully");

            } else {
                setNotes((prevNotes) =>
                    prevNotes.filter((note) => note._id !== newNote._id)
                );
                toast.error("Failed to add the note. Please try again.");
            }

        } catch (error) {

            console.error("Error adding notes:", error);
            setNotes((prevNotes) =>
                prevNotes.filter((note) => note._id !== newNote._id)
            );
            toast.error("An error occurred while adding the note. Please try again.");
        } finally {
            setIsAdding(false);
        }

    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && event.shiftKey) {
            // Allow new line for Shift+Enter
            return;
        }
        if (event.key === "Enter" && !event.shiftKey && !isAdding && currentNote.trim() !== "") {
            event.preventDefault();
            handleAdd();
        }
    }

    const handleDeleteClick = (note) => {
        setSelectedNote(note);
        setShowModal(true);
    };

    const handleDeleteConfirm = async () => {

        // Backup the current state
        const previousNotes = [...notes];
        const updatedNotes = notes.filter((note) => note._id !== selectedNote);
        setNotes(updatedNotes);
        setShowModal(false);
        setSelectedNote(null);

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "delete",
            brand_number: currentUser.brand_number,
            mobile: selectedMobileNumber,
            id: selectedNote
        };

        try {

            const response = await axios.post(`${BASE_URL2}/whatsapp_notes`, body);
            if (response.data.success) {
                toast.success("Note deleted successfully")
            } else {
                // Revert the UI if the API response indicates failure
                setNotes(previousNotes);
                toast.error("Failed to delete the note. Please try again.");

            }

        } catch (error) {
            console.error("Error deleting note:", error);
            setNotes(previousNotes);
            toast.error("An error occurred while deleting the note. Please try again.");

        }


    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedNote(null);
    };

    const handleEditClick = (note) => {
        setEditingNoteId(note._id);
        setEditText(note.text); // Populate the textarea with the current note text
    };

    const handleSaveClick = async (note) => {
        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "update",
            brand_number: currentUser.brand_number,
            mobile: selectedMobileNumber,
            id: note._id,
            text: editText,
        };

        const previousNotes = [...notes];
        setNotes((prevNotes) =>
            prevNotes.map((n) =>
                n._id === note._id ? { ...n, text: editText } : n
            )
        );
        try {
            const response = await axios.post(`${BASE_URL2}/whatsapp_notes`, body);
            if (response.data.success) {
                toast.success("Note updated successfully");
            } else {
                setNotes(previousNotes);
                toast.error("Failed to update the note. Please try again.");
            }

        } catch (error) {
            console.error("Error saving notes:", error);
            setNotes(previousNotes);
            toast.error("An error occurred while updating the note. Please try again.");

        }
        finally {
            setEditingNoteId(null); // Exit edit mode
        }


    };

    const handleCancelEdit = () => {
        setEditingNoteId(null);
        setEditText(""); // Clear edit state
    };

    return (
        <div className={`${style.noteCard} shadow`}>
            <header className="d-flex justify-content-between align-items-center p-2">
                <h4 className={`${style.title} m-0`}>My Notes</h4>
                <IoCloseSharp
                    onClick={handleClose}
                    className={`${style.closeIcon} clickable`}
                />
            </header>
            <div className={style.mainDiv}>


                {notesLoading ?
                    (<div className="text-center py-4">
                        <CircularProgress />
                    </div>)
                    : (<>
                        {notes.length > 0 ? (
                            <div className={`${style.notesContainer} py-2`}>
                                {notes.map((note) => (
                                    <div key={note._id} className={`${style.note} d-flex flex-column`}>
                                        {/* condition for converting to text area on edit */}
                                        {editingNoteId === note._id ? (
                                            <textarea
                                                className={`${style.textarea} form-control`}
                                                value={editText}
                                                onChange={(e) => setEditText(e.target.value)}
                                            />
                                        ) : (
                                            <p className="mb-1" style={{ wordBreak: "break-all" }}>{note.text}</p>
                                        )}

                                        <div className="d-flex justify-content-between align-items-center">
                                            <small className={`${style.noteMeta}`}>
                                                By: {note.agent_name} • {formatTimestamp(note.date)}
                                            </small>
                                            <div className={style.options}>
                                                {editingNoteId === note._id ? (
                                                    < div className="d-flex justify-content-center align-items-center">
                                                        <button
                                                            className="btn btn-success btn-sm mx-1"
                                                            onClick={() => handleSaveClick(note)}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary btn-sm"
                                                            onClick={handleCancelEdit}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                ) : (
                                                    currentUser.user_type === "admin" || (currentUser.user_id === note.agent_id) ? (<>
                                                        <CiEdit
                                                            onClick={() => handleEditClick(note)}
                                                            className="clickable mx-1"
                                                        />
                                                        <MdDelete
                                                            className="clickable mx-1"
                                                            onClick={() =>
                                                                handleDeleteClick(note._id)
                                                            }
                                                        />
                                                    </>) : null
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-4" style={{ height: "77%" }}>
                                <p className="text-muted">No notes found.</p>
                            </div>
                        )}

                        {/* Delete Confirmation Modal */}
                        <DeleteModal
                            show={showModal}
                            onConfirm={handleDeleteConfirm}
                            onCancel={handleCancel}
                        />




                        <div className={`${style.addNoteContainer} mt-3`}>
                            <textarea
                                className={`${style.textarea} form-control`}
                                placeholder="Write your note here..."
                                value={currentNote}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setCurrentNote(e.target.value)}
                            />
                            <button
                                className={`${style.addButton} btn btn-primary w-100 mt-2`}
                                onClick={handleAdd}
                                disabled={currentNote === "" || isAdding}
                            >
                                {isAdding ? "Adding..." : "Add Note"}
                            </button>
                        </div>
                    </>
                    )}

            </div>
        </div>
    );
};

export default NotesCard;
