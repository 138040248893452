import { useEffect } from "react";
import { toast } from "react-toastify";
import { parseReminder } from "../../utils/Utils";
const ReminderToasts = ({ groupedReminders }) => {
    useEffect(() => {
        const activeTimers = [];
        const todayReminders = groupedReminders.today;
    
        todayReminders.forEach((reminder) => {
            const reminderDate = new Date(reminder.date.replace(" ", "T"));
            const currentTime = new Date();
            const fiveMinutesBefore = new Date(reminderDate.getTime() - 5 * 60 * 1000);
            const { name, number, reminderText } = parseReminder(reminder.text);
    
            if (fiveMinutesBefore > currentTime) {
                const timeUntilToast = fiveMinutesBefore.getTime() - currentTime.getTime();
    
                // console.log(`Setting timeout for reminder "${reminder.text}" in ${timeUntilToast}ms`);
    
                const timerId = setTimeout(() => {
                    // console.log(`Executing toast for reminder "${reminder.text}"`);
                    toast.info(`Reminder: ${name} ${number}: ${reminderText}`, {
                        position: "top-right",
                        autoClose: 60000,
                    });
                }, timeUntilToast);
    
                activeTimers.push(timerId);
            }
        });
    
    
        return () => {
            // Cleanup active timers
            activeTimers.forEach((timerId) => clearTimeout(timerId));
            // console.log("Cleared timeouts on unmount or re-render.");
        };
    }, [groupedReminders]);
    

    return null; // This component only manages toasts
};

export default ReminderToasts;
