import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import {
    DataGrid, GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChatState } from "../../context/AllProviders";
import { CircularProgress } from '@mui/material';
const Table = ({ data }) => {
    const navigate = useNavigate();
    const [chatLoading, setChatLoading] = useState(false);

    const { handleChatOpen } = ChatState();

    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }

    const handleClick = async (chat) => {
        setChatLoading(true);
        await handleChatOpen(chat);
        setChatLoading(false);
        navigate(`/dashboard/?mob=${chat.mobile}`)

    }

    const CustomToolbar = () => (
        <GridToolbarContainer>
            <GridToolbarExport />
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={500} />
        </GridToolbarContainer>
    );

    const columns = Object.keys(data[0]).map((key) => ({
        field: key,
        headerName: key,
        flex: 1, // Flex allows dynamic column resizing
        renderCell: (params) =>
            key === "History" ? (
                <Link
                    to={params.value}
                    style={{ textDecoration: "none", color: "blue", cursor: "pointer" }}
                >
                    View Report
                </Link>
            ) : key === "View Chat" ? (
                <span
                    onClick={() => handleClick(params.value)}
                    style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                >
                    View
                </span>
            ) : key === "Status" ?(
                <span
                    style={params.value==="Busy"?{ color: "red" }:{color:"green"}}
                >
                    {params.value}
                </span>

            ):key === "Chat Status" ?(
                <span
                    style={params.value==="Active"?{ color: "green" }:params.value==="Waiting"?{color:"red"}:{color:""}}
                >
                    {params.value}
                </span>

            ):(
                params.value
            ),
    }));

const rows = data.map((row, index) => ({
    id: index, // Assign a unique ID to each row
    ...row,
}));


return (
    <>
        {chatLoading && (
            <div
                style={{
                    position: "fixed",
                    zIndex: 100,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0,.05)", // Semi-transparent black background
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(1px)", // Adds blur effect
                }}
            >
                <CircularProgress />
            </div>
        )}

        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20]}
                autoHeight
                disableSelectionOnClick
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </Box>
    </>

)

};

export default Table;