import React from "react";
import styles from "./inputBar.module.css" // Import the CSS module
import { useContext } from "react";
import { ChatContext } from "../../context/ChatContext";
import Input from "../Input";
const Inputbar = ({ notes, setshowNotesCard, setShowContactDetail, activeTab, setActiveTab, setshowQuickReply }) => {
    const { data } = useContext(ChatContext);
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);

        if (tabName === 'Note') {
            setshowNotesCard((prevState) => {
                const newState = !prevState; // Calculate the new state
                if (!newState) {
                    // If the new state is `false`, reset the active tab to ""
                    setActiveTab("");
                }
                return newState;
            });
            setShowContactDetail(false);
            setshowQuickReply(false);

        } else if (tabName === "quickReplies") {
            setshowQuickReply((prevState) => {
                const newState = !prevState; // Calculate the new state
                if (!newState) {
                    // If the new state is `false`, reset the active tab to ""
                    setActiveTab("");
                }
                return newState;
            });
            setShowContactDetail(false);
            setshowNotesCard(false);
        }
       
    };


    return (
        <div className={styles.inputContainer}>
            <header className="d-flex justify-content-start align-items-center w-100">

                <button
                    onClick={() => handleTabClick('Note')}
                    className={`btn ${styles.button} ms-2 ${activeTab === 'Note' ? styles.activeButton : ''}`}
                >
                    Notes {notes.length > 0 ? `(${notes.length})` : ''}
                </button>
                <button
                    onClick={() => handleTabClick('quickReplies')}
                    className={`btn ${styles.button} ms-2 ${activeTab === 'quickReplies' ? styles.activeButton : ''}`}
                >
                    Quick Replies
                </button>
            </header>


            <div className={styles.textArea}>

                <Input selectedMobile={data.selectedMobile} convData={data} />
            </div>

            {/* <footer className="d-flex justify-content-between align-items-center w-100 mt-3">
               
            </footer> */}
        </div>
    );
};

export default Inputbar;
