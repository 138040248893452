import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import LeftMenu from "../components/LeftMenu";
// import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Select, { components } from "react-select";
import { AuthContext } from "../context/AuthContext";
import { BASE_URL2, BASE_URL3 } from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import styles from './setting.module.css'
const Setting = () => {
  const [selectedHs, setSelectedHs] = useState("");
  const [selectedOption, setSelectedOption] = useState("team");
  const [agentList, setAgentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([""]);
  const { currentUser } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("Hunt Strategy");
  const [label, setLabel] = useState("");
  const [labels, setLabels] = useState([]);
  const [tagStatus, setTagStatus] = useState(false);
  const [waitingTime, setWaitingTime] = useState("");
  const [waitingStatus, setWaitingStatus] = useState(false);

  const handleAddLabel = async () => {
    if (label.trim() === "") return;

    const prevLabels = [...labels];
    const body = {
      user_id: currentUser.parent_id,
      token: currentUser.parent_token,
      methoid: "create",
      name: label,
      list_type: "wp_chat",
      description: "description",


    }
    setLabels([...labels, label.trim()]);
    try {
      const response = await axios.post(`${BASE_URL3}/contact-list.php`, body)
      if (response.success) {
        toast.success("Label added successfully")
      }

    } catch (error) {
      toast.error("Unable to add Label, please try again later");
      setLabels([...prevLabels]);

    }
    finally {
      setLabel(""); // Clear the input field

    }

  }

  const handleRemoveLabel = (index) => {
    const updatedLabels = labels.filter((_, i) => i !== index);
    setLabels(updatedLabels); // Update your label state
  };


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (currentUser.parent_id) {
      fetchAgent(selectedOption);
    }
  }, [currentUser, selectedOption]);
  const fetchAgent = async (selectType) => {
    setIsLoading(true);
    const forAgentdata = {
      user_id: currentUser.parent_id,
      token: currentUser.parent_token,
      method: "retrieve",
      agent_type: selectType,
    };
    try {
      const { data } = await axios.post(
        `${BASE_URL2}/whatsapp_agent`,
        forAgentdata
      );

      if (data.success === true) {
        const newAgentList = data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setAgentList(newAgentList);
        setIsLoading(false);
      } else {
        setAgentList([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    if (!currentUser.parent_id) {
      return;
    }
    const retriveHuntST = async () => {
      const dataforsetting = {
        user_id: currentUser.parent_id,
        token: currentUser.parent_token,
        method: "retrieve_hunt_strategy",
        user_type: currentUser.user_type,
        brand_number: currentUser.brand_number,
        channel: "whatsapp",
      };
      try {
        const { data } = await axios.post(
          `${BASE_URL2}/whatsapp_setting`,
          dataforsetting
        );
        if (data.success === true) {

          setSelectedHs(data.data.hunt_strategy);
          const arr = [data.data.teams]
          setSelectedAgent(arr)

        }
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      }
    };
    retriveHuntST();
  }, [currentUser]);

  // useEffect(() => {
  //   if (currentUser.user_type !== "admin") return;
  //   const fetchLabels = async () => {
  //     const body = {
  //       user_id: currentUser.parent_id,
  //       token: currentUser.parent_token,
  //       method: "wp_list_retrieve",
  //       mobile: "",
  //       name: ""
  //     };

  //     try {
  //       const response = await axios.post(`${BASE_URL2}/contact_list`, body);
  //       if (response.data.success) {
  //         setLabels(response.data.data.all_list); // Store labels if fetch is successful

  //       }
  //     } catch (error) {
  //       console.error("Error fetching labels:", error);
  //     }
  //   };

  //   fetchLabels();
  // }, [currentUser]);

  const handleHuntStrategyChange = (event) => {
    setSelectedHs(event.target.value);
  };

  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handleAgent = (e) => {
    setSelectedAgent(e);

  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    let agentList;
    if (selectedOption === "agent") {
      agentList = selectedAgent.map((list) => list.value);
    }

    const dataforsetting = {
      user_id: currentUser.parent_id,
      token: currentUser.parent_token,
      method: "hunt_strategy",
      user_type: currentUser.user_type,
      brand_number: currentUser.brand_number,
      agent_type: selectedOption,
      channel: "whatsapp",
      hunt_strategy: selectedHs,
      agent_id: selectedOption === "agent" ? agentList : selectedAgent.value,
      tag_status: tagStatus ? "1" : "0"
    };

    try {
      const { data } = await axios.post(
        `${BASE_URL2}/whatsapp_setting`,
        dataforsetting
      );
      if (data.success === true) {
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleTag = (e) => {
    const value = e.target.checked;
    setTagStatus(value ? true : false);
  };

  const handleWaitingStatus = (e) => {
    setWaitingStatus(e.target.checked);
  }

  return (
    <div>
      <div className="layout-wrapper d-lg-flex">
        <LeftMenu />
        <div className="w-100">
          <Navbar />
          <main className="d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-start p-3">

            <div
              className={`${styles.chatSettingsCard} d-flex flex-column justify-content-center justify-content-lg-start align-items-center bg-white `}
            >
              <div className="mb-2">
                <h4>Chat Settings</h4>
              </div>
              <div className={`${styles.settingsOptions} d-flex`}>
                <div
                  className={`${styles.option} ${activeTab === "Hunt Strategy" ? styles.active : ""
                    }`}
                  onClick={() => handleTabClick("Hunt Strategy")}
                >
                  <span className={styles.icon}>⚙️</span>
                  <span className={styles.label}>Hunt Strategy</span>
                </div>
                {currentUser.user_type === "admin" && <div
                  className={`${styles.option} ${activeTab === "Label" ? styles.active : ""
                    }`}
                  onClick={() => handleTabClick("Label")}
                >
                  <span className={styles.icon}>👤</span>
                  <span className={styles.label}>Label</span>
                </div>}
              </div>


            </div>

            <div className="w-100 d-flex justify-content-center align-items-center">
              {activeTab === "Hunt Strategy" ? <div className={`${styles.card} bg-white`}>
                <div className="d-flex justify-content-center align-items-center">
                  <form onSubmit={handleUpdate} style={{ width: "100%" }}>
                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <div className="d-flex ml-3">
                        <label>Tag Priority</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={tagStatus}
                            onChange={handleTag}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 mx-auto mb-4">
                      <label className="mb-2">
                        Hunt Strategy
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mb-2">
                        <select
                          className="form-control"
                          value={selectedHs}
                          onChange={handleHuntStrategyChange}
                          required
                        >
                          <option value="">
                            Select Hunt Strategy
                          </option>
                          <option value="random">Random</option>
                          {/* <option value="sequence">Sequence</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      {agentList.length > 0 && (<>
                        <label className="mb-2">
                          {selectedOption === "agent"
                            ? "Agents"
                            : selectedOption === "team"
                              ? "Select Team"
                              : "Managers"}
                        </label>

                        <div className="mt-1">
                          <Select
                            placeholder={`Select ${selectedOption}`}
                            onChange={handleAgent}
                            options={agentList}
                            isLoading={isLoading}
                            value={selectedAgent}
                            isMulti={
                              selectedOption === "agent"
                                ? true
                                : false
                            }
                            components={
                              selectedOption === "agent"
                                ? { Option }
                                : undefined
                            }
                            styles={customStyles}
                            classNamePrefix="select"
                            hideSelectedOptions={false}
                            required
                          />
                        </div>
                      </>
                      )}
                    </div>

                    <div className="col-md-12 mx-auto mb-4">
                      <div className="d-flex ml-3 mb-3">
                        <label> Waiting Time </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={waitingStatus}
                            onChange={handleWaitingStatus}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>

                      {waitingStatus &&
                        <div className="mb-2">
                          <input type="text" className="form-control" placeholder="Set waiting time" value={waitingTime} onChange={(e) => setWaitingTime(e.target.value)} />
                        </div>
                      }
                    </div>



                    <div
                      className="form-group col-md-2 w-100"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                      >
                        Update
                      </button>
                    </div>

                  </form>
                </div>
              </div> :
                <div className={`${styles.labelCard} bg-white shadow p-4`}>
                  <label className={`${styles.header}`}>Add Label</label>
                  <div className={`${styles.labelList} mb-3`}>
                    {labels.map((t, index) => (
                      <div key={index} className={`${styles.labelContainer}`}>
                        <span className={`${styles.labelText}`}>{t}</span>
                        <button
                          className={`${styles.removeButton}`}
                        // onClick={() => handleRemoveLabel(index)}
                        >
                          ✖
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.inputGroup} d-flex`}>
                    <input
                      type="text"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      className={`${styles.input} form-control`}
                      placeholder="Enter a label"
                    />
                    <button
                      className={`${styles.addButton} btn btn-primary`}
                      onClick={handleAddLabel}
                    >
                      Add
                    </button>
                  </div>


                </div>
              }

            </div>




          </main>
        </div>
      </div>
    </div>
  );
};

export default Setting;
