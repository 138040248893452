import { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { BASE_URL2 } from '../api/api';
import { AuthContext } from '../context/AuthContext';
import { ChatState } from '../context/AllProviders';
const useReminders = (fetchType) => {
    const [reminders, setReminders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { selectedMobileNumber } = ChatState();


    const fetchAllParams = useMemo(() => ({
        user_id: currentUser.parent_id,
        token: currentUser.parent_token,
        method: "retrieve_all",
        brand_number: currentUser.brand_number,
    }), [currentUser]);

    // Fetch parameters for "retrieve"
    const fetchMobileParams = useMemo(() => ({
        user_id: currentUser.parent_id,
        token: currentUser.parent_token,
        method: "retrieve",
        mobile: selectedMobileNumber,
        brand_number: currentUser.brand_number,
    }), [currentUser, selectedMobileNumber]);

    useEffect(() => {
        if(!currentUser.parent_id && !currentUser.parent_token) return;
        if ((fetchType === "retrieve" && !selectedMobileNumber) ||(fetchType === "retrieve_all" && selectedMobileNumber)) return;
        const fetchReminders = async () => {
            setLoading(true); // Set loading to true at the start of the request
            const params = fetchType === "retrieve_all" ? fetchAllParams : fetchMobileParams;

            axios
                .post(`${BASE_URL2}/whatsapp_reminder`, params)
                .then((response) => {
                    if (response.data.success) {
                        setReminders(response.data.data);
                    } else{
                        setReminders([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching reminders:", error);
                })
                .finally(() => {
                    setLoading(false); // Set loading to false when the request completes (success or failure)
                });
        };

        fetchReminders();
    }, [fetchType, fetchAllParams, fetchMobileParams]);




     const groupedReminders = useMemo(() => {
        const todayDate = new Date().toDateString();

        return {
            today: reminders.filter((reminder) => {
                const reminderDate = new Date(reminder.date.replace(" ", "T"));
                return reminderDate.toDateString() === todayDate;
            }),
            upcoming: reminders.filter((reminder) => {
                const reminderDate = new Date(reminder.date.replace(" ", "T"));
                const today = new Date();
                return (
                    reminderDate > today &&
                    reminderDate.toDateString() !== todayDate
                );
            }),
            previous: reminders.filter((reminder) => {
                const reminderDate = new Date(reminder.date.replace(" ", "T"));
                return reminderDate < new Date(new Date().setHours(0, 0, 0, 0));
            }),
        };
    }, [reminders]);

    return { reminders, loading, setReminders, groupedReminders };
};

export default useReminders;
